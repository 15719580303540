<template>
  <PushCardList
    :isEmbed="isEmbed"
    :embedRoomId="embedRoomId"
    :env="env"
    :webinarId="webinarId"
    :scene="scene"
  />
</template>

<script>
  import { PushCardList } from '@vhcl/push-card';
  import { isEmbed, embedRoomId } from '@/utils/utils.js';
  export default {
    name: 'PushCards',
    components: {
      PushCardList
    },
    props: {
      scene: {
        type: String,
        default: 'webinar' // webinar 活动层级；material 资料库
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        embedRoomId: embedRoomId(),
        env: process.env.VUE_APP_ENV,
        webinarId: this.$route.params.str
      };
    }
  };
</script>
<style lang="less" scoped>
  .zdy-vh-ui {
    .zdy-vh-ui-confirm {
      color: #fff;
      background-color: #fb2626;
      border-color: #fb2626;
      &:hover {
        background: #d4151c;
        border-color: #d4151c;
        color: #fff;
      }
      &:active,
      &:focus {
        background: #ad0914;
        border-color: #ad0914;
        color: #fff;
        outline: 0;
      }
    }
    .zdy-confirm-cancel {
      color: #262626;
      border-color: #d9d9d9;
      background: transparent;
      &:hover {
        color: #262626;
        border-color: #8c8c8c;
        background: #fff;
      }
      &:active,
      &:focus {
        color: #262626;
        border-color: #595959;
        background: #fff;
        outline: 0;
      }
    }
  }
</style>
<style lang="less" scoped>
  /* 列表样式-------------------------------------- */

  .vmp-card-list {
    margin-top: 34px;
  }
</style>
